import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const HomePage = ({ data, location }) => {
  const [videoId, setVideoId] = useState("")
  const createLinks = e => {
    e.preventDefault()
    e.stopPropagation()

    window.gtag &&
      window.gtag("event", "click", {
        event_category: "Button",
        event_label: "Search video",
      })

    setVideoId("")

    const videoLink = document.getElementById("video-link-input").value

    if (videoLink.trim().length === 0) {
      return
    }

    const videoIdMatch = videoLink.match(/(\?|&)v=(.+?)(&|$)/)

    if (videoIdMatch === null) {
      return
    }

    const videoId = videoIdMatch[2]

    setVideoId(videoId)
  }

  return (
    <Layout mainClassName="home-page">
      <SEO title="Home" pathname={location.pathname} />
      <section className="hero">
        <div className="inner">
          <div className="hero-main">
            <h1 className="text__l">
              Private or deleted video in your YouTube playlist?
            </h1>
            <p className="text__xl">
              Try to <span>find it now</span>
            </p>
            {videoId ? (
              <div className="hero-video-links">
                <p>Look at these links (in this order):</p>
                <ul>
                  <li>
                    <a
                      className="text__m"
                      href={`https://web.archive.org/web/*/https://www.youtube.com/watch?v=${videoId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="se-image">
                        <Img
                          fixed={
                            data.internetArchiveImg?.childImageSharp?.fixed
                          }
                          alt="Internet Archive logo"
                        />
                      </div>
                      <span>
                        Internet Archive{" "}
                        <span className="text__s">(try different dates)</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text__m"
                      href={`https://www.google.com/search?q=${videoId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="se-image">
                        <Img
                          fixed={data.googleImg?.childImageSharp?.fixed}
                          alt="Google logo"
                        />
                      </div>
                      <span>Google</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text__m"
                      href={`https://www.bing.com/search?q=${videoId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="se-image">
                        <Img
                          fixed={data.bingImg?.childImageSharp?.fixed}
                          alt="Bing logo"
                        />
                      </div>
                      <span>Bing</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text__m"
                      href={`https://duckduckgo.com/?q=${videoId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="se-image">
                        <Img
                          fixed={data.duckImg?.childImageSharp?.fixed}
                          alt="Duck Duck Go logo"
                        />
                      </div>
                      <span>Duck Duck Go</span>
                    </a>
                  </li>
                </ul>
                <p>
                  No luck? You can also try your{" "}
                  <a
                    href="https://www.wikihow.com/View-Browsing-History"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    browser's history
                  </a>
                  .
                </p>
                <button
                  className="btn__primary"
                  type="button"
                  onClick={() => setVideoId("")}
                >
                  Search another video
                </button>
              </div>
            ) : (
              <form onSubmit={createLinks}>
                <input
                  id="video-link-input"
                  type="text"
                  placeholder="Insert YouTube video link"
                  aria-label="Insert YouTube video link"
                  required
                />
                <button className="btn__primary">Search</button>
              </form>
            )}
          </div>
          <div className="hero-image">
            <Img
              fixed={data.iphoneImg?.childImageSharp?.fixed}
              alt="Mobile phone showing deleted video"
            />
          </div>
        </div>
      </section>
      <section className="backup">
        <div className="angle-start"></div>
        <div className="content">
          <div className="inner">
            <h2 className="text__l">Don't want to lose any more videos?</h2>
            <p className="text__m">
              <Link to="/backup-videos/">Backup your YouTube playlists</Link>
            </p>
            <h2 className="text__l">Already have a backup?</h2>
            <p className="text__m">
              <Link to="/recover-videos/">
                Recover private or deleted videos
              </Link>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query HomePageQuery {
    iphoneImg: file(relativePath: { eq: "iphone.png" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    internetArchiveImg: file(relativePath: { eq: "se-archive.png" }) {
      childImageSharp {
        fixed(width: 26) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    googleImg: file(relativePath: { eq: "se-google.png" }) {
      childImageSharp {
        fixed(width: 29) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bingImg: file(relativePath: { eq: "se-bing.png" }) {
      childImageSharp {
        fixed(width: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    duckImg: file(relativePath: { eq: "se-duck.png" }) {
      childImageSharp {
        fixed(width: 31) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
